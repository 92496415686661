<template>
  <div>
    <b-card-header class="pb-50">
      <h5>Filtros</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Nome" label-for="searchName">
            <b-form-input id="name" v-model="name" @keyup.enter="search" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Status" label-for="searchStatus">
            <v-select
              id="searchStatus"
              multiple
              v-model="status"
              :reduce="(status_list) => status_list.id"
              :options="statusList"
              :loading="loading.status"
              :close-on-select="false"
              label="name"
              @keyup.enter="search"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Objetivo" label-for="searchObjective">
            <v-select
              id="searchObjective"
              multiple
              v-model="goal"
              :reduce="(goal_list) => goal_list.key"
              :options="goals"
              :loading="loading.goals"
              :close-on-select="false"
              label="name"
              @keyup.enter="search"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Faixa de Renda" label-for="searchIncome">
            <v-select
              id="searchIncome"
              multiple
              v-model="income"
              :reduce="(income_list) => income_list.id"
              :options="incomes"
              :loading="loading.incomes"
              label="name"
              @keyup.enter="search"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Data de criação" label-for="searchCreatedAt">
            <flat-pickr
              id="searchCreatedAt"
              v-model="createdAt"
              class="form-control"
              :config="flatPickrConfig"
              :placeholder="`01 Jan 2020 até 31 Dez ${actualYear}`"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group
            label="Data para lembrar"
            label-for="searchCallReminder"
          >
            <flat-pickr
              id="searchCallReminder"
              v-model="callReminder"
              class="form-control"
              :config="flatPickrConfig"
              :placeholder="`01 Jan 2020 até ${generateTodayDate()}`"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Origem" label-for="origin">
            <v-select
              id="origin"
              v-model="origin"
              :reduce="(lead_origin) => lead_origin.key"
              :options="origins"
              :loading="loading.origins"
              label="name"
              @input="recommendedBy = undefined"
              @keyup.enter="search"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Campanha" label-for="searchCampaign">
            <v-select
              id="searchCampaign"
              multiple
              v-model="campaign"
              :reduce="(campaign_list) => campaign_list.id"
              :options="campaigns"
              :loading="loading.campaigns"
              :close-on-select="false"
              :disabled="!isCustomerCampaign"
              label="alias"
              @keyup.enter="search"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <dynamic-select
            id="searchRecommendedBy"
            label="Recomendado por"
            placeholder="Digite o nome do cliente"
            v-model="recommendedBy"
            :disabled="!isCustomerRecommendation"
            :options="customers"
            :loading="loading.recommendedBy"
            @find="findCustomers"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Profissão" label-for="searchOccupations">
            <v-select
              id="searchOccupations"
              multiple
              v-model="occupation"
              :reduce="(occupationsList) => occupationsList.id"
              :options="occupationsList"
              :loading="loading.occupation"
              :close-on-select="false"
              label="name"
              @keyup.enter="search"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group
            label="PN ativo?"
            label-for="searchConsultantsActive"
          >
            <v-select
              id="searchConsultantsActive"
              v-model="consultantsActive"
              :reduce="(assert) => assert.value"
              :options="assertBooleanOptions"
              label="name"
              @keyup.enter="search"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group
            label="Parceiro de negócio (PN)"
            label-for="searchConsultant"
          >
            <v-select
              id="searchConsultant"
              multiple
              v-model="consultant"
              :reduce="(consultant_list) => consultant_list.id"
              :options="consultantList"
              :loading="loading.consultantList"
              :close-on-select="false"
              label="name"
              @keyup.enter="search"
              @input="consultantChange"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group
            label="Tipo de visualização"
            label-for="searchStructureOption"
          >
            <v-select
              id="searchStructureOption"
              v-model="structureOption"
              :reduce="(structure_list) => structure_list.key"
              :options="structures"
              :loading="loading.structures"
              label="name"
              @keyup.enter="search"
              :disabled="hasConsultantSelected"
              :clearable="false"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Franqueado" label-for="searchFranchise">
            <v-select
              id="searchFranchise"
              multiple
              v-model="franchise"
              :reduce="(franchise_list) => franchise_list.id"
              :options="franchises"
              :loading="loading.franchises"
              :close-on-select="false"
              label="name"
              @keyup.enter="search"
              @input="consultantChange"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Poupa">
            <vue-slider
              class="mt-2 primary"
              v-model="monthlySavings"
              :max="20000"
              :direction="dir"
            >
              <template v-slot:tooltip="{ value }">
                <div v-if="value <= 19999" class="custom-tooltip">
                  {{ value | toCurrency }}
                </div>
                <div v-else class="custom-tooltip">
                  >{{ value | toCurrency }}
                </div>
              </template>
            </vue-slider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BFormRadioGroup,
  BFormRadio,
  BButton,
  BSpinner,
  BPopover,
  BCardText,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import DynamicSelect from "@/modules/shared/components/DynamicSelect";
import VueSlider from "vue-slider-component";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import todayButton from "@/helpers/todayButton";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import { OWN, STRUCTURE } from "@/constants/structure_options";
import * as accountTypes from "@/modules/account/store/types";
import {
  isPnRole,
} from "@/constants/auth";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import {
  LEAD_TYPE_PPN,
  LEAD_ORIGIN_RECOMMENDATION,
  LEAD_ORIGIN_REC_CUSTOMER,
  ORIGIN_CAMPAIGN,
} from "@/constants/lead";
import { assertBooleanOptions } from "@/constants/assertions";
import { dateToShortFormat } from "@/helpers/converters";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormRadioGroup,
    BFormRadio,
    BButton,
    BSpinner,
    vSelect,
    BCardText,
    BPopover,
    DynamicSelect,
    VueSlider,
    BFormCheckbox,
    flatPickr,
  },
  props: {
    search: Function,
    spinnerActive: Boolean,
  },
  data() {
    return {
      assertBooleanOptions,
      loading: {
        goals: false,
        status: false,
        incomes: false,
        recommendedBy: false,
        consultantList: false,
        occupation: false,
        franchises: false,
        structures: false,
        origins: false,
        campaigns: false
      },
      flatPickrConfig: {
        altFormat: "j M Y",
        altInput: true,
        mode: "range",
        dateFormat: "Y-m-d",
        locale: Portuguese,
        plugins: [ShortcutButtonsPlugin(todayButton)],
      },
      name: undefined,
      createdAt: undefined,
      goal: undefined,
      status: undefined,
      income: undefined,
      recommendedBy: undefined,
      occupation: undefined,
      franchise: undefined,
      callReminder: undefined,
      structureOption: OWN,
      consultant: undefined,
      monthlySavings: [0, 20000],
      origin: undefined,
      consultantsActive: undefined,
      campaign: undefined,
      dir: "ltr",
    };
  },
  setup() {
    const actualYear = new Date().getFullYear()
    return { toast: useToast(), actualYear, dateToShortFormat };
  },
  computed: {
    ...mapGetters({
      goals: types.GOALS,
      incomes: types.INCOMES,
      origins: sharedTypes.LEAD_ORIGINS,
      customers: sharedTypes.CUSTOMERS,
      statusList: types.STATUSES,
      franchises: sharedTypes.FRANCHISES,
      structures: sharedTypes.STRUCTURE_TYPES,
      consultantList: sharedTypes.CONSULTANTS,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
      user: accountTypes.USER,
      occupationsList: sharedTypes.OCCUPATIONS,
      campaigns: sharedTypes.CAMPAIGNS
    }),
    hasConsultantFranchiseSelected: function () {
      return (
        (this.consultant && this.consultant.length > 0) ||
        (this.franchise && this.franchise.length > 0)
      );
    },
    hasConsultantSelected: function () {
      return this.consultant && this.consultant.length > 0;
    },
    isPn: function () {
      return isPnRole(this.user?.user_role_id);
    },
    isCustomerRecommendation: function () {
      return this.origin && this.origin == LEAD_ORIGIN_RECOMMENDATION;
    },
    isCustomerCampaign: function () {
      return this.origin && this.origin == ORIGIN_CAMPAIGN;
    },
  },
  mounted() {
    this.structureOption = this.isPn ? OWN : STRUCTURE;
    this.loading.origins = true;
    this.getOrigins({ LEAD_TYPE_PPN })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as origens para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.origins = false;
      });
    this.loading.incomes = true;
    this.getIncomes()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as faixas de renda para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.incomes = false;
      });
    this.loading.goals = true;
    this.getGoals()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os objetivos para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.goals = false;
      });
    this.loading.status = true;
    this.getStatuses()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os status para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.status = false;
      });
    this.loading.franchises = true;
    this.getFranchises()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as franquias para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.franchises = false;
      });
    this.loading.structures = true;
    this.getStructures()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as estruturas para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.structures = false;
      });
    this.loading.campaigns = true
    this.getCampaigns()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as campanhas para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.campaigns = false
      });
    this.loading.consultantList = true;
    this.getConsultants()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os consultores para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.consultantList = false;
      }),
      (this.loading.occupation = true);
    this.getOccupations()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os consultores para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.occupation = false;
      });
    if (this.$route.query.startDate && this.$route.query.endDate) {
      this.callReminder =
        this.$route.query.startDate + " até " + this.$route.query.endDate;
      this.search();
    }
    if (this.$route.query.structureType) {
      this.structureOption = this.$route.query.structureType;
      this.search();
    }
  },
  methods: {
    ...mapActions({
      getIncomes: types.GET_INCOMES,
      getGoals: types.GET_GOALS,
      getOrigins: sharedTypes.GET_LEAD_ORIGINS,
      getCustomers: sharedTypes.GET_CUSTOMERS,
      getStatuses: types.GET_STATUSES,
      getFranchises: sharedTypes.GET_FRANCHISES,
      getStructures: sharedTypes.GET_STRUCTURE_TYPES,
      getConsultants: sharedTypes.GET_CONSULTANTS,
      getOccupations: sharedTypes.GET_OCCUPATIONS,
      getCampaigns: sharedTypes.GET_CAMPAIGNS,
    }),
    findCustomers(keyword) {
      this.loading.recommendedBy = true;
      this.getCustomers({ keyword })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os clientes para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.recommendedBy = false;
        });
    },
    consultantChange() {
      if (
        (this.consultant && this.consultant.length > 0) ||
        (this.franchise && this.franchise.length > 0)
      ) {
        this.structureOption = STRUCTURE;
      }
    },
    generateTodayDate() {
      return this.dateToShortFormat(new Date());
    }
  },
};
</script>

<style lang="scss">
$themeColor: #f26237;
@import "~vue-slider-component/lib/theme/default.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.form-group {
  margin-bottom: 0;
}
</style>
