<template>
  <div>
    <new-lead-sidebar />
    <div style="position: relative;">
      <app-breadcrumb :breadcrumbTitle="false">
        <b-button v-if="canCreateLead" variant="primary" @click="openNewLeadSidebar(undefined)" style="position: absolute; right: 13;">
          <feather-icon icon="PlusCircleIcon" class="mr-50" />
          <span class="text-nowrap">Novo lead de cliente</span>
        </b-button>
      </app-breadcrumb>
    </div>   
    <b-card no-body class="card-statistics">
      <b-card-header class="p-2">
        <b-card-title>Totalizador de leads clientes</b-card-title>
      </b-card-header>
      <b-card-body class="px-3">
        <b-row
          class="grid-status"
        >
          <b-col cols="auto" class="mt-1">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <div class="circle total_leads">
                  <img
                    class="img_icon"
                    src="~@/assets/images/svg/icn_all_leads.svg"
                  />
                </div>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ totalizer[status.TOTAL_AMOUNT] }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Total leads
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col cols="auto" class="mt-1">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-button
                  class="btn-icon"
                  variant="flat"
                  style="padding: 0"
                  @click="filterStatus(status.CUSTOMER_STATUS_HOT)"
                >
                  <div class="circle total_hot">
                    <img
                      class="img_icon"
                      src="~@/assets/images/svg/icn_hot_active.svg"
                    />
                  </div>
                </b-button>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ totalizer[status.CUSTOMER_STATUS_HOT] || 0 }}
                </h4>
                <b-card-text class="font-small-3 mb-0"> Quente </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col cols="auto" class="mt-1">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-button
                  class="btn-icon"
                  variant="flat"
                  style="padding: 0"
                  @click="filterStatus(status.CUSTOMER_STATUS_COLD)"
                >
                  <div class="circle total_cold">
                    <img
                      class="img_icon"
                      src="~@/assets/images/svg/icn_cold_active.svg"
                    />
                  </div>
                </b-button>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ totalizer[status.CUSTOMER_STATUS_COLD] || 0 }}
                </h4>
                <b-card-text class="font-small-3 mb-0"> Frio </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col cols="auto" class="mt-1">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-button
                  class="btn-icon"
                  variant="flat"
                  style="padding: 0"
                  @click="filterStatus(status.CUSTOMER_STATUS_DISINTERESTED)"
                >
                  <div class="circle total_disinterested">
                    <img
                      class="img_icon"
                      src="~@/assets/images/svg/icn_disinterested_active.svg"
                    />
                  </div>
                </b-button>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ totalizer[status.CUSTOMER_STATUS_DISINTERESTED] || 0 }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Desinteressado
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col cols="auto" class="mt-1">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-button
                  class="btn-icon"
                  variant="flat"
                  style="padding: 0"
                  @click="filterStatus(status.CUSTOMER_STATUS_EA)"
                >
                  <div class="circle total_ea">
                    <img
                      class="img_icon"
                      src="~@/assets/images/svg/icn_ea.svg"
                    />
                  </div>
                </b-button>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ totalizer[status.CUSTOMER_STATUS_EA] || 0 }}
                </h4>
                <b-card-text class="font-small-3 mb-0"> EA </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col cols="auto" class="mt-1">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-button
                  class="btn-icon"
                  variant="flat"
                  style="padding: 0"
                  @click="filterStatus(status.CUSTOMER_STATUS_EF)"
                >
                  <div class="circle total_ef">
                    <img
                      class="img_icon"
                      src="~@/assets/images/svg/icn_interview_checked.svg"
                    />
                  </div>
                </b-button>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ totalizer[status.CUSTOMER_STATUS_EF] || 0 }}
                </h4>
                <b-card-text class="font-small-3 mb-0"> EF </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BPopover,
  BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import * as customerStatus from "@/constants/customers_status";
import NewLeadSidebar from "../components/NewLeadSidebar";
import AppBreadcrumb from "@core/layouts/components/AppBreadcrumb.vue";
import {
  LEAD_CUSTOMER,
  CREATE_ACTION
} from "@/constants/resources";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    VueApexCharts,
    BPopover,
    BButton,
    NewLeadSidebar,
    AppBreadcrumb,
  },
  props: {
    totalizer: {
      type: Object,
      default: undefined,
    },
    filterStatus: Function,
    openNewLeadSidebar: Function,
  },
  computed: {
    status: function () {
      return customerStatus;
    },
    canCreateLead: function() {
      return this.$can(CREATE_ACTION, LEAD_CUSTOMER)
    },
  },
};
</script>

<style lang="scss">
.total_leads {
  background-color: #22505f;
}
.total_hot {
  background-color: #492f3a;
}
.total_cold {
  background-color: #223f61;
}
.total_disinterested {
  background-color: #48453b;
}
.total_ea {
  background-color: #4a2859;
}
.total_ef {
  background-color: #224f4f;
}
.circle {
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 100%;
}
.img_icon {
  width: 30px;
  height: 30px;
}
#buttons {
  border: 0px;
}

.grid-status {
  display: grid !important;
  grid-template-columns: repeat(5, 1fr);

  @media (max-width: 1455px) {
    & {
      grid-template-columns: repeat(3, 1fr) !important;
    }
  }

  @media (max-width: 986px) {
    & {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
}
</style>
