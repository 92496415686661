export const LEAD_TYPE_PPN = 'PPN'
export const LEAD_TYPE_PCL = 'PCL'

export const LEAD_ORIGIN_RECOMMENDATION = "recommendation"
export const LEAD_ORIGIN_REC_CUSTOMER = "rec_customer"

export const LANDING_PAGE = "landing_page";
export const ADMIN = "admin";
export const REC_PN = "rec_pn";
export const OWN_CONTACT = "self";
export const CAMPAIGN_MARKETING = "campaign_marketing";
export const HUNTING_LINKEDIN = "hunting_linkedin";
export const HUNTING_SOCIAL = "hunting_social";
export const HUNTING_WHATSAPP = "hunting_whatsapp";
export const PLANNERS_WHATSAPP_GROUP = "planners_whatsapp_group";
export const CV = "cv";
export const CV_CATHO = "cv_catho";
export const CV_LINKEDIN = "cv_linkedin";
export const CV_OTHERS = "cv_others";

export const SELF_ORIGIN = "self";
export const ORIGIN_CAMPAIGN = "campaign";